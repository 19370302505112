@import 'fonts';

html {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    text-size-adjust: none;

    html[lang='it'] & {
        text-align: justify;
    }
}

input,
button,
textarea,
select {
    margin: 0;
    padding: 0;
    color: inherit;
    font-weight: inherit;
    font-size: 99%;
    font-family: inherit;
    border-radius: 0;
}

textarea,
button,
input[type='email'],
input[type='tel'],
input[type='text'],
input[type='submit'],
input[type='search'] {
    appearance: none;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button {
    display: none;
}

textarea[disabled],
button[disabled],
input[type='email'][disabled],
input[type='tel'][disabled],
input[type='text'][disabled],
input[type='submit'][disabled],
input[type='search'][disabled] {
    cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type='checkbox'][disabled] + label,
input[type='radio'][disabled] + label {
    cursor: not-allowed;
}

table {
    font-size: inherit;
    border-collapse: collapse;
    border-spacing: 0;
}

table td,
table th {
    padding: 4px 15px;
    vertical-align: top;
}

tr > :first-child {
    padding-left: 0;
}

th {
    color: #082939;
    font-weight: 700;
}

caption,
strong,
b {
    font-weight: 700;
}

i,
em {
    font-weight: 400;
}

ul ul {
    margin-bottom: 0;
}

form,
figure,
blockquote {
    margin: 0;
    padding: 0;
}

img,
iframe {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border: none;
    border: 0;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption {
    display: block;
}

html {
    font-size: 15px;
}

body {
    color: #646464;
    font-weight: 400;
    font-size: 15px;
    font-family: Montserrat, sans-serif;
    line-height: 1.625;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

a {
    color: $link-color;
    text-decoration: underline;
}

caption,
th,
td {
    // noinspection CssOverwrittenProperties
    text-align: left;

    // noinspection CssOverwrittenProperties
    text-align: start;
}

abbr,
acronym {
    font-variant: normal;
    border-bottom: 1px dotted #3c3c3c;
    cursor: help;
}

blockquote,
q {
    quotes: none;
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

del {
    text-decoration: line-through;
}

ins {
    text-decoration: none;
}

pre,
code {
    font-family: monospace;
}

/* Vertical align */
.float_left {
    float: left;
}

.float_right {
    float: right;
}

/* Clear floats */
.clear,
#clear {
    clear: both;
    height: 0.1px;
    font-size: 0.1px;
    line-height: 0.1px;
}

/* Hide invisible elements */
.invisible {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
}

.mod_navigation ul,
.mod_navigation li,
.mod_changelanguage ul,
.mod_changelanguage li,
.mod_breadcrumb ul,
.mod_breadcrumb li,
.ce_gallery ul,
.ce_gallery li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.block-link {
    position: relative;
    display: inline-block;
    padding-bottom: 9px;
    color: $link-color;
    font-weight: 600;
    font-size: inherit;
    line-height: inherit;
    text-transform: uppercase;
    text-decoration: none;
    background: none;
    border: 0;
    cursor: pointer;

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 2px;
        background: $link-color;
        content: '';
    }

    &:hover {
        color: $link-color;

        &::after {
            animation: 0.5s ease 0s underline;
        }
    }
}

.section-center {
    padding-right: 30px;
    padding-left: 30px;

    @include screen(large) {
        max-width: 1200px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 15px;
        padding-left: 15px;
    }
}

.headline {
    color: #082939;
    font-weight: 700;
    font-family: brandon-grotesque, sans-serif;
    text-transform: uppercase;
}

.list {
    ul,
    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    li {
        position: relative;
        padding-left: 16px;
        background: url('../images/list.svg') 0 calc(0.5em) no-repeat;
        background-size: 5px 8px;
    }
}

@keyframes underline {
    0% {
        left: 50%;
        width: 0;
    }

    100% {
        left: 0;
        width: 100%;
    }
}
