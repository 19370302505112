.team-element {
    &__title {
        @extend .headline;
        margin: 0 0 0.5em !important;
        font-size: 22px !important;
        cursor: pointer;

        &:hover,
        .team-element__item--active & {
            color: $link-color;
        }
    }

    &__details {
        display: none;
        margin: 40px -30px 50px 22%;

        .team-element__item--active & {
            display: block;
        }
    }

    &__image {
        margin: 0 0 30px;
    }

    &__name {
        font-weight: 600;
        text-transform: uppercase;
    }

    @include screen(medium) {
        position: relative;
        min-height: 660px;

        &__image {
            img {
                width: 860px;
                max-width: none;
            }
        }

        &__details {
            position: absolute;
            top: 0;
            right: 0;
            width: 66%;
            margin: 0;
        }
    }
}
