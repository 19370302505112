.training-list {
    &__item {
        position: relative;
        margin-right: -30px;
        margin-bottom: 30px;
        padding: 90px 60px 90px 30px;
        background: #fff;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__headline {
        margin: 0 0 15px;
        @extend .headline;
        font-size: 24px;
        line-height: 1.2;
    }

    &__text {
        margin: 0 0 25px;
    }

    &__date {
        display: block;
        margin: 0 0 25px;
        color: #646464;
        font-weight: 300;
    }

    &__link {
        @extend .block-link;
    }

    @include screen(medium) {
        margin-bottom: 100px;
    }

    @include screen(large) {
        &__item {
            margin-right: 0;
            padding: 90px 30px;

            &::before {
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: -1;
                width: 0;
                background: #fff;
                content: '';

                .block-article--bg-left & {
                    right: calc(-50vw + 615px);
                    width: 50vw;
                }

                .block-article--bg-right & {
                    left: calc(-50vw + 615px);
                    width: 50vw;
                }
            }
        }
    }
}

.training-detail {
    &__intro {
        font-size: 18px;
        line-height: 1.5;

        strong {
            display: block;
            color: $link-color;
            font-size: 24px;
            text-transform: uppercase;
        }
    }

    &__summary,
    &__textblock {
        margin-top: 50px;

        p:first-child {
            margin-top: 0;
        }
    }

    h2 {
        margin: 80px 0 50px;
    }

    &__notes {
        margin: 80px 0 50px;
    }

    &__apply {
        @extend .block-link;
    }

    &__contact {
        margin-top: 100px;

        &--desktop {
            display: none;
        }
    }

    @include screen(medium) {
        &__intro {
            font-size: 24px;

            p {
                margin-bottom: 0;
            }

            strong {
                font-size: 36px;
            }
        }

        &__textblock {
            margin-top: 100px;
        }

        &__contact {
            margin-top: 0;

            &--desktop {
                display: block;
            }

            &--mobile {
                display: none;
            }
        }
    }
}
