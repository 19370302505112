.section-footer {
    color: #fff;
    font-size: 12px;
    background: #082939;

    &__inside {
        @extend .section-center;
        display: flex;
        flex-wrap: wrap;
        padding: 30px 20px;
    }

    a {
        color: #8dc8d5;
    }

    &__navigation {
        a,
        strong {
            position: relative;
            display: inline-block;
            color: #e5e7e6;
            font-weight: normal;
            line-height: 25px;
            text-decoration: none;

            &::after {
                position: absolute;
                bottom: 0;
                left: 50%;
                display: block;
                width: 0;
                height: 1px;
                background: #e5e7e6;
                transition:
                    width 0.5s ease 0s,
                    left 0.5s ease 0s;
                content: '';
            }

            &:hover::after {
                left: 0;
                width: 100%;
            }
        }

        > ul > li {
            display: block;
            padding: 9px 0;
            overflow: hidden;
            border-bottom: 1px solid #5d5d5d;

            > a,
            > strong {
                margin: 0;
                padding: 0;
                color: #e5e7e6;
                font-weight: 600;
                font-size: 16px;
                line-height: 27px;

                &::after {
                    height: 2px;
                }

                @include screen(medium) {
                    float: left;
                    margin-right: 15px;
                }
            }

            ul {
                overflow: hidden;
            }

            li {
                display: inline-block;
                margin: 2px 4px 2px 0;
                padding: 0 10px 0 0;
                border-right: 1px solid #5d5d5d;

                &:last-child {
                    margin-right: 0;
                    border: none;
                }
            }
        }

        &--aboutus {
            > ul > li {
                border-bottom: none;
            }
        }
    }

    &__address {
        width: 50%;
        margin-top: 50px;

        p {
            margin: 1.5em 0;
        }
    }

    &__social {
        text-align: right;

        a {
            display: inline-block;
            width: 25px;
            height: 25px;
            margin-left: 25px;
            text-align: left;
            text-indent: -999em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;

            &[href*='linkedin.com'] {
                background-image: url('../images/linkedin-footer.svg');
            }

            &[href*='facebook.com'] {
                background-image: url('../images/facebook-footer.svg');
            }

            &[href*='instagram.com'] {
                background-image: url('../images/instagram-footer.svg');
            }
        }
    }

    &__scrolltop {
        position: fixed;
        right: 15px;
        bottom: 115px;
        z-index: 100;
        text-align: right;

        a {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 40px;
            text-align: left;
            text-indent: -999em;
            background: #82d8b7 0 0 no-repeat;
            background-size: contain;
            border-radius: 50%;

            &::before {
                position: absolute;
                top: 16px;
                left: 15px;
                width: 10px;
                height: 10px;
                border-top: 2px solid #fff;
                border-left: 2px solid #fff;
                transform: rotateZ(45deg);
                content: '';
            }
        }

        @include screen(medium) {
            display: none;
        }
    }

    &__closure {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 50%;
    }

    &__copyright {
        margin-top: 70px;
        margin-bottom: 1.5em;
        color: #b7b5b3;
        text-align: right;

        p {
            margin: 0;
        }
    }

    @include screen(medium) {
        &__inside {
            padding-top: 35px;
            padding-bottom: 45px;
        }

        &__navigations {
            width: 75%;
        }

        &__address {
            width: 16.6666%;
            margin-top: 0;
            margin-left: 8.3333%;
        }

        &__closure {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 100%;
            margin-top: 50px;
        }

        &__social {
            width: 16.6667%;
            text-align: left;

            a {
                margin-right: 25px;
                margin-left: 0;
            }
        }

        &__copyright {
            margin: 10px 0 0;
            text-align: left;

            p,
            .mod_navigation,
            ul,
            li {
                display: inline;
            }

            p,
            li {
                &::after {
                    display: inline;
                    white-space: pre;
                    content: '    |    ';
                }
            }

            li:last-child::after {
                content: none;
            }
        }
    }
}
