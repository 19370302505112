.process-graph-element {
    margin-top: 60px;

    &__headline {
        @extend .headline;
        margin: 0 0 10px;
        font-weight: 600;
        font-size: 15px;
    }

    &__text {
        margin: 0;
        font-size: 13px;
    }

    &__header {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom: 50px;

        &::after {
            position: absolute;
            top: 1.2em;
            bottom: 0;
            left: 50%;
            z-index: -1;
            width: 6px;
            margin-left: -3px;
            background: #e4e5ea;
            content: '';
        }
    }

    &__block {
        &--1 {
            width: 50%;
            padding-right: 10px;
            text-align: right;
        }

        &--2 {
            width: 50%;
            padding-left: 10px;
        }

        &--3,
        &--4,
        &--5,
        &--6,
        &--7,
        &--8 {
            position: relative;
            width: 260px;
            min-height: 245px;
            margin: 0 auto;
            padding: 75px 0;
            text-align: center;

            &::before {
                position: absolute;
                top: 0;
                left: 50%;
                z-index: -1;
                display: block;
                width: 170px;
                height: 170px;
                margin-left: -85px;
                background: #fff 65px 20px no-repeat;
                background-size: 40px 40px;
                border-radius: 50%;
                content: '';
            }

            &::after {
                position: absolute;
                top: 170px;
                bottom: 0;
                left: 50%;
                z-index: -1;
                display: block;
                width: 6px;
                margin-left: -3px;
                background: #e4e5ea;
                content: '';
            }
        }

        &--3 {
            &::before {
                background-image: url('../../images/process-graph--mobile3.svg');
            }
        }

        &--4 {
            &::before {
                background-image: url('../../images/process-graph--mobile4.svg');
            }
        }

        &--5 {
            &::before {
                background-image: url('../../images/process-graph--mobile5.svg');
            }
        }

        &--6 {
            &::before {
                background-image: url('../../images/process-graph--mobile6.svg');
            }
        }

        &--7 {
            &::before {
                background-image: url('../../images/process-graph--mobile7.svg');
            }
        }

        &--8 {
            min-height: 0;
            padding-bottom: 0;

            &::before {
                background-image: url('../../images/process-graph--mobile8.svg');
            }

            &::after {
                content: none;
            }
        }
    }

    @include screen(medium) {
        width: 900px;

        &__header {
            padding-bottom: 20px;

            &::after {
                content: none;
            }
        }

        &__body {
            position: relative;
            height: 600px;
            background: url('../../images/process-graph--desktop.svg') 50% 0 no-repeat;
            background-size: contain;
        }

        &__block {
            &::before,
            &::after {
                content: none;
            }

            &--1 {
                width: 300px;
                text-align: left;
            }

            &--2 {
                width: 300px;
                text-align: right;
            }

            &--3,
            &--4,
            &--5,
            &--6,
            &--7,
            &--8 {
                position: absolute;
                min-height: 0;
                margin: 0;
                padding: 0;
            }

            &--3,
            &--5,
            &--7 {
                left: 40px;
                text-align: right;
            }

            &--4,
            &--6,
            &--8 {
                left: 620px;
                text-align: left;
            }

            &--3 {
                top: 60px;
            }

            &--4 {
                top: 160px;
            }

            &--5 {
                top: 260px;
            }

            &--6 {
                top: 360px;
            }

            &--7 {
                top: 470px;
            }

            &--8 {
                top: 530px;
            }
        }
    }
}
