@include screen(medium) {
    .col,
    .col-3,
    .col-4,
    .col-6,
    .col-8 {
        float: left;
        width: 100%;
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
    }

    .row::after {
        display: table;
        clear: both;
        content: '';
    }

    .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem;
    }

    .row > * {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
    }

    .col-3 {
        width: 25%;
    }

    .col-4 {
        width: 33.3333%;
    }

    .col-6 {
        width: 50%;
    }

    .col-8 {
        width: 66.6667%;
    }

    .offset-3 {
        margin-left: 25%;
    }

    .offset-4 {
        margin-left: 33.3333%;
    }

    .offset-8 {
        margin-left: 66.6667%;
    }
}
