.mod_newslist {
    @include screen(medium) {
        display: flex;
    }
}

.news-list {
    margin-top: 45px;
    margin-bottom: 60px;

    &__item {
        img {
            width: 100%;
            height: auto;
        }
    }

    &__date {
        display: block;
        font-weight: 300;
    }

    &__headline {
        margin: 1.5em 0 !important;
        color: #646464 !important;
        font-weight: 400 !important;
        font-size: inherit !important;
    }

    &__more {
        @extend .block-link;
    }

    .tns-liveregion {
        display: none;
    }

    .tns-nav {
        display: flex;
        justify-content: center;
        margin: 40px 0;

        button {
            display: block;
            width: 8px;
            height: 8px;
            margin-right: 8px;
            font-size: 8px;
            line-height: 8px;
            text-indent: -999em;
            background: #eeeff4;
            border: 0;
            border-radius: 50%;
            cursor: pointer;

            &.tns-nav-active {
                background: $link-color;
            }

            @include screen(medium) {
                width: 15px;
                height: 15px;
                margin-right: 15px;
                font-size: 15px;
                line-height: 15px;
            }
        }
    }
}
