.list-element {
    margin-top: 0;
    margin-bottom: 110px;

    .col-4:nth-last-child(1) & {
        margin-bottom: 0;
    }

    &__icon {
        width: 60px;
    }

    &__headline {
        @extend .headline;
        font-size: 24px;
    }

    &__link {
        @extend .block-link;
        margin-top: 30px;
    }

    @extend .list;

    @include screen(medium) {
        .col-4:nth-last-child(2) &,
        .col-4:nth-last-child(3) & {
            margin-bottom: 0;
        }
    }
}
