/* cyrillic-ext */
@font-face {
  font-weight: 100;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUOjIg1_i6t8kCHKm459WxZqh7p29NfpiOjk20.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 100;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUOjIg1_i6t8kCHKm459WxZqh7g29NfpiOjk20.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 100;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUOjIg1_i6t8kCHKm459WxZqh7r29NfpiOjk20.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 100;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUOjIg1_i6t8kCHKm459WxZqh7q29NfpiOjk20.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 100;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUOjIg1_i6t8kCHKm459WxZqh7k29NfpiOj.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 200;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat ExtraLight Italic"), local("Montserrat-ExtraLightItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZBg_z8fZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 200;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat ExtraLight Italic"), local("Montserrat-ExtraLightItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZBg_z-PZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 200;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat ExtraLight Italic"), local("Montserrat-ExtraLightItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZBg_z8_Zwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 200;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat ExtraLight Italic"), local("Montserrat-ExtraLightItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZBg_z8vZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 200;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat ExtraLight Italic"), local("Montserrat-ExtraLightItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZBg_z_PZwjimrqw.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 300;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZYgzz8fZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 300;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZYgzz-PZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 300;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZYgzz8_Zwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 300;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZYgzz8vZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 300;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZYgzz_PZwjimrqw.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 400;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Italic"), local("Montserrat-Italic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 400;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Italic"), local("Montserrat-Italic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 400;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Italic"), local("Montserrat-Italic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 400;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Italic"), local("Montserrat-Italic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 400;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Italic"), local("Montserrat-Italic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 500;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZOg3z8fZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 500;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZOg3z-PZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 500;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZOg3z8_Zwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 500;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZOg3z8vZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 500;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZOg3z_PZwjimrqw.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 600;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZFgrz8fZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 600;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZFgrz-PZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 600;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZFgrz8_Zwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 600;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZFgrz8vZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 600;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZFgrz_PZwjimrqw.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 700;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz8fZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 700;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz-PZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 700;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz8_Zwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 700;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz8vZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 700;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz_PZwjimrqw.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 800;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZbgjz8fZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 800;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZbgjz-PZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 800;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZbgjz8_Zwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 800;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZbgjz8vZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 800;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZbgjz_PZwjimrqw.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 900;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZSgnz8fZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 900;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZSgnz-PZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 900;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZSgnz8_Zwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 900;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZSgnz8vZwjimrq1Q_.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 900;
  font-family: Montserrat;
  font-style: italic;
  src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZSgnz_PZwjimrqw.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 100;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Thin"), local("Montserrat-Thin"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm45_QpRxC7m0dR9pBOi.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 100;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Thin"), local("Montserrat-Thin"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm45_QpRzS7m0dR9pBOi.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 100;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Thin"), local("Montserrat-Thin"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm45_QpRxi7m0dR9pBOi.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 100;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Thin"), local("Montserrat-Thin"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm45_QpRxy7m0dR9pBOi.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 100;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Thin"), local("Montserrat-Thin"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm45_QpRyS7m0dR9pA.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 200;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_aZA3gTD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 200;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_aZA3g3D_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 200;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_aZA3gbD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 200;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_aZA3gfD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 200;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_aZA3gnD_vx3rCs.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 300;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Light"), local("Montserrat-Light"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gTD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 300;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Light"), local("Montserrat-Light"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3g3D_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 300;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Light"), local("Montserrat-Light"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gbD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 300;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Light"), local("Montserrat-Light"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gfD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 300;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Light"), local("Montserrat-Light"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gnD_vx3rCs.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 400;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 400;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 400;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 400;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 400;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 500;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gTD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 500;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3g3D_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 500;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gbD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 500;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gfD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 500;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gnD_vx3rCs.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 600;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gTD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 600;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3g3D_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 600;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gbD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 600;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gfD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 600;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gnD_vx3rCs.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 700;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gTD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 700;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3g3D_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 700;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gbD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 700;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gfD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 700;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 800;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3gTD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 800;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3g3D_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 800;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3gbD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 800;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3gfD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 800;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3gnD_vx3rCs.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-weight: 900;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Black"), local("Montserrat-Black"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3gTD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-weight: 900;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Black"), local("Montserrat-Black"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3g3D_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-weight: 900;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Black"), local("Montserrat-Black"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3gbD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-weight: 900;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Black"), local("Montserrat-Black"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3gfD_vx3rCubqg.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-weight: 900;
  font-family: Montserrat;
  font-style: normal;
  src: local("Montserrat Black"), local("Montserrat-Black"), url("https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3gnD_vx3rCs.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  text-size-adjust: none;
}
html[lang=it] body {
  text-align: justify;
}

input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: inherit;
  font-size: 99%;
  font-family: inherit;
  border-radius: 0;
}

textarea,
button,
input[type=email],
input[type=tel],
input[type=text],
input[type=submit],
input[type=search] {
  appearance: none;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  display: none;
}

textarea[disabled],
button[disabled],
input[type=email][disabled],
input[type=tel][disabled],
input[type=text][disabled],
input[type=submit][disabled],
input[type=search][disabled] {
  cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type=checkbox][disabled] + label,
input[type=radio][disabled] + label {
  cursor: not-allowed;
}

table {
  font-size: inherit;
  border-collapse: collapse;
  border-spacing: 0;
}

table td,
table th {
  padding: 4px 15px;
  vertical-align: top;
}

tr > :first-child {
  padding-left: 0;
}

th {
  color: #082939;
  font-weight: 700;
}

caption,
strong,
b {
  font-weight: 700;
}

i,
em {
  font-weight: 400;
}

ul ul {
  margin-bottom: 0;
}

form,
figure,
blockquote {
  margin: 0;
  padding: 0;
}

img,
iframe {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: none;
  border: 0;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption {
  display: block;
}

html {
  font-size: 15px;
}

body {
  color: #646464;
  font-weight: 400;
  font-size: 15px;
  font-family: Montserrat, sans-serif;
  line-height: 1.625;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

a {
  color: #a82c2c;
  text-decoration: underline;
}

caption,
th,
td {
  text-align: left;
  text-align: start;
}

abbr,
acronym {
  font-variant: normal;
  border-bottom: 1px dotted #3c3c3c;
  cursor: help;
}

blockquote,
q {
  quotes: none;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

del {
  text-decoration: line-through;
}

ins {
  text-decoration: none;
}

pre,
code {
  font-family: monospace;
}

/* Vertical align */
.float_left {
  float: left;
}

.float_right {
  float: right;
}

/* Clear floats */
.clear,
#clear {
  clear: both;
  height: 0.1px;
  font-size: 0.1px;
  line-height: 0.1px;
}

/* Hide invisible elements */
.invisible {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
}

.mod_navigation ul,
.mod_navigation li,
.mod_changelanguage ul,
.mod_changelanguage li,
.mod_breadcrumb ul,
.mod_breadcrumb li,
.ce_gallery ul,
.ce_gallery li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.block-link, .training-detail__apply, .training-list__link, .news-list__more, .job-details__apply, .job-list__link, .list-element__link, .image-link-element__link, .section-prefooter__contact a, .teaser-element__link, .widget-submit button {
  position: relative;
  display: inline-block;
  padding-bottom: 9px;
  color: #a82c2c;
  font-weight: 600;
  font-size: inherit;
  line-height: inherit;
  text-transform: uppercase;
  text-decoration: none;
  background: none;
  border: 0;
  cursor: pointer;
}
.block-link::after, .training-detail__apply::after, .training-list__link::after, .news-list__more::after, .job-details__apply::after, .job-list__link::after, .list-element__link::after, .image-link-element__link::after, .section-prefooter__contact a::after, .teaser-element__link::after, .widget-submit button::after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #a82c2c;
  content: "";
}
.block-link:hover, .training-detail__apply:hover, .training-list__link:hover, .news-list__more:hover, .job-details__apply:hover, .job-list__link:hover, .list-element__link:hover, .image-link-element__link:hover, .section-prefooter__contact a:hover, .teaser-element__link:hover, .widget-submit button:hover {
  color: #a82c2c;
}
.block-link:hover::after, .training-detail__apply:hover::after, .training-list__link:hover::after, .news-list__more:hover::after, .job-details__apply:hover::after, .job-list__link:hover::after, .list-element__link:hover::after, .image-link-element__link:hover::after, .section-prefooter__contact a:hover::after, .teaser-element__link:hover::after, .widget-submit button:hover::after {
  animation: 0.5s ease 0s underline;
}

.section-center, .section-footer__inside, .section-prefooter .block-article__inside, .section-main .block-article__inside, .section-header__inside {
  padding-right: 30px;
  padding-left: 30px;
}
@media screen and (min-width: 1200px) {
  .section-center, .section-footer__inside, .section-prefooter .block-article__inside, .section-main .block-article__inside, .section-header__inside {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.headline, .training-list__headline, .job-list__headline, .team-element__title, .process-graph-element__headline, .list-element__headline, .image-link-element__headline, .contact-element h1.contact-element__headline, .block-article h1, .section-prefooter__contact p, .teaser-element__headline {
  color: #082939;
  font-weight: 700;
  font-family: brandon-grotesque, sans-serif;
  text-transform: uppercase;
}

.list ul, .list-element ul, .block-article .ce_list ul, .block-article .ce_text ul,
.block-article .ce_intro ul,
.list li,
.list-element li,
.block-article .ce_list li,
.block-article .ce_text li,
.block-article .ce_intro li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.list li, .list-element li, .block-article .ce_list li, .block-article .ce_text li,
.block-article .ce_intro li {
  position: relative;
  padding-left: 16px;
  background: url("../images/list.svg") 0 0.5em no-repeat;
  background-size: 5px 8px;
}

@keyframes underline {
  0% {
    left: 50%;
    width: 0;
  }
  100% {
    left: 0;
    width: 100%;
  }
}
.widget {
  position: relative;
  display: block;
  margin: 0 0 18px;
  color: #4c4c47;
  font-size: inherit;
}
.widget > label,
.widget .radio_container > legend,
.widget .checkbox_container > legend {
  position: absolute;
  top: -8px;
  left: 0;
  margin: 0 1px 0 0;
  padding: 0;
  font-size: 12px;
  background: #fff;
  cursor: text;
  transition: all 0.2s;
}
.widget > label span.mandatory,
.widget .radio_container > legend span.mandatory,
.widget .checkbox_container > legend span.mandatory {
  margin-left: 3px;
}
.widget select {
  appearance: none;
}
.widget textarea {
  width: 100%;
}
.widget input.text,
.widget select,
.widget textarea,
.widget .radio_container,
.widget .checkbox_container {
  width: 100%;
  padding: 16px 0 8px;
  font-size: inherit;
  border: 0;
  border-bottom: 1px solid #646464;
  border-radius: 0;
}
.widget input.text.no-label,
.widget select.no-label,
.widget textarea.no-label,
.widget .radio_container.no-label,
.widget .checkbox_container.no-label {
  padding-top: 15px;
  padding-bottom: 13px;
}
.widget input.text::placeholder,
.widget select::placeholder,
.widget textarea::placeholder,
.widget .radio_container::placeholder,
.widget .checkbox_container::placeholder {
  color: #b5b5b5;
  opacity: 1;
  transition: all 0.2s;
}
.widget input.text:placeholder-shown:not(:focus, .no-label)::placeholder,
.widget select:placeholder-shown:not(:focus, .no-label)::placeholder,
.widget textarea:placeholder-shown:not(:focus, .no-label)::placeholder,
.widget .radio_container:placeholder-shown:not(:focus, .no-label)::placeholder,
.widget .checkbox_container:placeholder-shown:not(:focus, .no-label)::placeholder {
  opacity: 0;
}
.widget input.text:focus,
.widget select:focus,
.widget textarea:focus,
.widget .radio_container:focus,
.widget .checkbox_container:focus {
  border-color: #a82c2c;
  outline: none;
}
.widget input.text:focus + label,
.widget select:focus + label,
.widget textarea:focus + label,
.widget .radio_container:focus + label,
.widget .checkbox_container:focus + label {
  color: #a82c2c;
}
.widget input.text:placeholder-shown:not(:focus) + label, .widget input.text.no-placeholder:not(:focus) + label,
.widget select:placeholder-shown:not(:focus) + label,
.widget select.no-placeholder:not(:focus) + label,
.widget textarea:placeholder-shown:not(:focus) + label,
.widget textarea.no-placeholder:not(:focus) + label,
.widget .radio_container:placeholder-shown:not(:focus) + label,
.widget .radio_container.no-placeholder:not(:focus) + label,
.widget .checkbox_container:placeholder-shown:not(:focus) + label,
.widget .checkbox_container.no-placeholder:not(:focus) + label {
  top: 13px;
  color: #b5b5b5;
  font-weight: 300;
  font-size: inherit;
}
.widget .radio_container,
.widget .checkbox_container {
  border-bottom: 0;
}
.widget .radio_container > span,
.widget .checkbox_container > span {
  display: inline-block;
  margin-right: 20px;
}
.widget .radio_container > span > label,
.widget .checkbox_container > span > label {
  margin-left: 5px;
}
.widget-submit {
  margin-top: 80px;
  border: none;
}
.widget p.error {
  position: relative;
  margin: 10px 0 0;
  padding: 8px 23px 7px;
  color: #fff;
  font-size: 0.8em;
  background: #a82c2c;
}
.widget p.error::before {
  position: absolute;
  top: -5px;
  left: 28px;
  width: 10px;
  height: 10px;
  background: #a82c2c;
  transform: rotateZ(45deg);
  content: "";
}
.widget.explanation--small {
  font-size: 10px;
  line-height: 1;
}
@media screen and (min-width: 960px) {
  .widget.explanation--small {
    margin-top: -2em;
  }
}
.widget-fineuploader::after {
  display: table;
  clear: both;
  content: "";
}
.widget-fineuploader label {
  position: initial;
  margin: 0;
  font-size: inherit;
}
.widget-fineuploader .qq-upload-button,
.widget-fineuploader .qq-upload-button-hover {
  display: inline-block;
  width: auto;
  padding: 5px 10px;
  color: #fff;
  font-size: 12px;
  line-height: 19px;
  background: #a82c2c;
  border: none;
  border-radius: 5px;
}
.widget-fineuploader .qq-upload-list li {
  color: inherit !important;
  background: transparent !important;
}
@media screen and (min-width: 960px) {
  .widget-fineuploader .qq-upload-button {
    float: left;
    width: 130px;
  }
  .widget-fineuploader .qq-drop-processing,
  .widget-fineuploader .qq-upload-list {
    margin-left: 150px;
  }
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

@media screen and (min-width: 960px) {
  .widget {
    margin-bottom: 23px;
  }
}
@media screen and (min-width: 960px) {
  .col,
  .col-3,
  .col-4,
  .col-6,
  .col-8 {
    float: left;
    width: 100%;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .row::after {
    display: table;
    clear: both;
    content: "";
  }
  .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
  .row > * {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.3333%;
  }
  .col-6 {
    width: 50%;
  }
  .col-8 {
    width: 66.6667%;
  }
  .offset-3 {
    margin-left: 25%;
  }
  .offset-4 {
    margin-left: 33.3333%;
  }
  .offset-8 {
    margin-left: 66.6667%;
  }
}
.layout--image .section-header {
  min-height: 75vw;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: contain;
}
@media screen and (min-width: 960px) {
  .layout--image .section-header {
    min-height: auto;
    background-size: cover;
  }
}
.layout--home.layout--image .section-header {
  min-height: 150vw;
}
@media screen and (min-width: 960px) {
  .layout--home.layout--image .section-header {
    min-height: auto;
  }
}
.section-header__inside {
  position: relative;
  padding-top: 40px;
  padding-bottom: 0;
}
@media screen and (min-width: 960px) {
  .section-header__inside {
    padding-top: 60px;
  }
}
.layout--image .section-header.layout--home .section-header__inside {
  height: 100vh;
}
@media screen and (min-width: 960px) {
  .layout--image .section-header__inside {
    height: 750px;
  }
}
.section-header__logo {
  display: block;
  width: 130px;
  height: 40px;
  margin-top: 8px;
  text-indent: -999em;
  background: url("../images/logo.svg") 0 0/contain no-repeat;
  background-size: contain;
}
@media screen and (min-width: 960px) {
  .section-header__logo {
    float: left;
    width: 175px;
    height: 50px;
  }
}
.section-header__navigation-toggle {
  position: absolute;
  top: 32px;
  right: 30px;
  z-index: 101;
  display: block;
  width: 50px;
  height: 50px;
  text-indent: -999em;
  background: #fff url("../images/hamburger.svg") 52% 50% no-repeat;
  background-size: 25px 18px;
  border-radius: 100%;
  cursor: pointer;
}
.section-header__navigation-overlay {
  position: absolute;
  inset: 0;
  z-index: 100;
  display: none;
  height: 100vh;
  background: #fff;
}
.section-header__navigation--active .section-header__navigation-toggle {
  background-image: url("../images/cross.svg");
}
.section-header__navigation--active .section-header__navigation-overlay {
  display: block;
}
.section-header__navigation .mod_navigation {
  height: 50vh;
  margin: 20vh 0 0;
  padding: 0 40px;
}
.section-header__navigation .mod_navigation ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.section-header__navigation .mod_navigation li {
  padding: 5px 0;
}
.section-header__navigation .mod_navigation a,
.section-header__navigation .mod_navigation strong {
  position: relative;
  display: inline-block;
  color: #082939;
  font-weight: 400;
  font-size: 25px;
  text-decoration: none;
}
.section-header__navigation .mod_navigation a::after,
.section-header__navigation .mod_navigation strong::after {
  position: absolute;
  bottom: -4px;
  left: 50%;
  display: block;
  width: 0;
  height: 6px;
  background: #082939;
  transition: width 0.5s ease 0s, left 0.5s ease 0s;
  content: "";
}
.section-header__navigation .mod_navigation a:hover::after, .section-header__navigation .mod_navigation a.active::after, .section-header__navigation .mod_navigation a.trail::after,
.section-header__navigation .mod_navigation strong:hover::after,
.section-header__navigation .mod_navigation strong.active::after,
.section-header__navigation .mod_navigation strong.trail::after {
  left: 0;
  width: 100%;
}
.section-header__navigation .mod_navigation a.login,
.section-header__navigation .mod_navigation strong.login {
  color: #a82c2c;
}
.section-header__navigation .mod_navigation a.login::after,
.section-header__navigation .mod_navigation strong.login::after {
  background: #a82c2c;
}
.section-header__navigation-social {
  position: absolute;
  bottom: 40px;
  left: 40px;
}
.section-header__navigation-social a {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  text-indent: -999em;
  background-size: contain;
}
.section-header__navigation-social a[href*="linkedin.com"] {
  background: url("../images/linkedin-header.svg") 0 0 no-repeat;
}
.section-header__navigation-social a[href*="facebook.com"] {
  background: url("../images/facebook-header.svg") 0 0 no-repeat;
}
.section-header__navigation-social a[href*="instagram.com"] {
  background: url("../images/instagram-header.svg") 0 0 no-repeat;
}
@media screen and (min-width: 960px) {
  .section-header__navigation-toggle {
    display: none;
  }
  .section-header__navigation-overlay {
    position: static;
    display: block;
    height: auto;
    background: none;
  }
  .section-header__navigation-social {
    display: none;
  }
  .section-header__navigation .mod_navigation {
    height: auto;
    margin: 18px 0 0;
    padding: 0;
  }
  .section-header__navigation .mod_navigation ul {
    flex-direction: row;
    justify-content: flex-end;
  }
  .section-header__navigation .mod_navigation li {
    margin-left: 50px;
    padding: 0;
  }
  .section-header__navigation .mod_navigation a,
  .section-header__navigation .mod_navigation strong {
    padding-bottom: 9px;
    font-size: 18px;
    line-height: 1;
  }
  .layout--white-nav .section-header__navigation .mod_navigation a,
  .layout--white-nav .section-header__navigation .mod_navigation strong {
    color: #fff;
  }
  .layout--white-nav .section-header__navigation .mod_navigation a::after,
  .layout--white-nav .section-header__navigation .mod_navigation strong::after {
    background: #fff;
  }
}

.section-teaser {
  position: relative;
  margin-top: -90px;
}
.section-teaser__inside {
  display: flex;
}
@media screen and (min-width: 1260px) {
  .section-teaser__inside {
    max-width: 1260px;
    margin-right: auto;
    margin-bottom: 70px;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .section-teaser__inside::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 33%;
    background: #fff;
    content: "";
  }
  .layout--home .section-teaser__inside::before {
    right: auto;
    left: 0;
  }
}
.section-teaser .block-article {
  position: relative;
  width: 100%;
}
.section-teaser .block-article__inside {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0;
}
.layout--home .section-teaser .block-article__inside {
  justify-content: flex-start;
}

.teaser-element {
  position: relative;
  flex-basis: 80%;
  padding: 30px 30px 0;
  background: #fff;
}
.teaser-element__headline {
  margin: 0;
  font-size: 18px;
  line-height: 1;
}
.teaser-element__text, .teaser-element__link {
  margin: 1em 0;
  font-size: 12px;
}
.teaser-element__link {
  margin-top: 2em;
}
.layout--home .teaser-element .teaser-element__headline, .teaser-element--with-text .teaser-element__headline {
  padding-right: 30px;
}
.layout--home .teaser-element .teaser-element__headline > span, .teaser-element--with-text .teaser-element__headline > span {
  position: absolute;
  display: inline-block;
  width: 15px;
  height: 10px;
  margin-top: 4px;
  margin-left: 0.5em;
  background: url("../images/teaser-link.svg") 0 0 no-repeat;
}
.layout--home .teaser-element:hover .teaser-element__headline > span, .teaser-element--with-text:hover .teaser-element__headline > span {
  animation: teaser-link 1s ease infinite;
}
@keyframes teaser-link {
  0% {
    margin-left: 0.5em;
  }
  50% {
    margin-left: 1em;
  }
  100% {
    margin-left: 1em;
  }
}
@media screen and (max-width: 960px) {
  .teaser-element--with-text .teaser-element__link, .layout--home .teaser-element__link {
    position: absolute;
    inset: 0;
    text-indent: -999em;
  }
  .teaser-element--with-text .teaser-element__link::after, .layout--home .teaser-element__link::after {
    content: none;
  }
}
.layout--home .teaser-element__headline {
  font-size: 24px;
}
.layout--home .teaser-element__headline > span {
  margin-top: 7px !important;
}
.layout--home .teaser-element__text {
  display: none;
}
@media screen and (min-width: 560px) {
  .teaser-element {
    flex-basis: 50%;
  }
}
@media screen and (min-width: 960px) {
  .teaser-element {
    flex-basis: 33%;
  }
  .teaser-element__headline {
    font-size: 36px !important;
  }
  .teaser-element__text, .teaser-element__link {
    font-size: 15px;
  }
  .layout--home .teaser-element__headline, .teaser-element--with-text .teaser-element__headline {
    padding-right: 0;
  }
  .layout--home .teaser-element__headline > span, .teaser-element--with-text .teaser-element__headline > span {
    display: none !important;
  }
  .teaser-element--with-text .teaser-element__link {
    position: relative;
    margin-top: 1em;
    text-indent: 0;
  }
  .teaser-element--with-text .teaser-element__link::before {
    content: none;
  }
}

.section-title .block-article__inside {
  margin-bottom: -50px;
  padding: 100px 30px 0;
}
.section-title .block-article p {
  display: none;
}
@media screen and (min-width: 960px) {
  .section-title .block-article {
    padding-top: 50px;
    line-height: 1.25;
  }
  .section-title .block-article p {
    display: block;
    color: #646464;
    font-weight: 300;
    font-size: 24px;
  }
}
@media screen and (min-width: 960px) {
  .layout--image .section-title .block-article {
    position: absolute;
    top: 300px;
    left: 0;
    width: 100%;
  }
  .layout--image .section-title .block-article,
  .layout--image .section-title .block-article .block-article__inside {
    padding-top: 0;
    padding-bottom: 0;
  }
  .layout--image .section-title .block-article h1,
  .layout--image .section-title .block-article p {
    width: 75%;
  }
  .layout--white-title .section-title .block-article h1,
  .layout--white-title .section-title .block-article p {
    color: #fff;
  }
}
@media screen and (min-width: 1200px) {
  .section-title .block-article__inside {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.section-main {
  clear: both;
  overflow-x: hidden;
}
.section-main .block-article__inside {
  margin-top: 100px;
  margin-bottom: 100px;
}
.section-main .block-article--full-width .block-article__inside {
  margin-bottom: 0;
  padding-right: 0;
  padding-left: 0;
}
.section-main .block-article--bg-left, .section-main .block-article--bg-right {
  position: relative;
}
.section-main .block-article--bg-left::before, .section-main .block-article--bg-right::before {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #eeeff4;
  content: "";
}
.section-main .block-article--bg-left .block-article__inside, .section-main .block-article--bg-right .block-article__inside {
  padding-top: 95px;
  padding-bottom: 100px;
}
.section-main .block-article--bg-left:last-child .block-article__inside, .section-main .block-article--bg-right:last-child .block-article__inside {
  margin-bottom: 0 !important;
}
.section-main .block-article--bg-left::before {
  right: 0;
  left: 10px;
}
@media screen and (min-width: 1200px) {
  .section-main .block-article--bg-left::before {
    left: calc(50% - 615px);
  }
}
.section-main .block-article--bg-right::before {
  right: 10px;
  left: 0;
}
@media screen and (min-width: 1200px) {
  .section-main .block-article--bg-right::before {
    right: calc(50% - 615px);
  }
}
.section-main .block-article:first-child .row > .col-4:first-child :not(.formbody) h2 {
  display: none;
}
@media screen and (min-width: 960px) {
  .section-main .block-article:first-child .row > .col-4:first-child :not(.formbody) h2 {
    display: block;
  }
}
@media screen and (min-width: 960px) {
  .section-main .block-article__inside {
    margin-top: 120px;
    margin-bottom: 130px;
  }
  .section-main .block-article .row > .col-4:first-child h2 {
    margin-top: 10px !important;
  }
  .section-main .block-article .row > .col-6:first-child h2 {
    margin-top: 0 !important;
  }
  .section-main .block-article .ce_text:first-child p:first-child,
  .section-main .block-article .ce_intro:first-child p:first-child {
    margin-top: 0;
  }
  .section-main .block-article--bg-left h2, .section-main .block-article--bg-right h2 {
    margin-top: 0;
  }
  .section-main .block-article--bg-right + .block-article {
    margin-top: -30px;
  }
  .section-main .block-article--bg-right + .block-article > .block-article__inside > h2:first-child {
    position: relative;
  }
  .section-main .block-article--bg-right + .block-article > .block-article__inside > h2:first-child::before {
    position: absolute;
    top: -100px;
    right: -30px;
    width: 500px;
    height: 110px;
    background: #eeeff4;
    content: "";
  }
}
@media screen and (min-width: 1200px) {
  .section-main .block-article__inside {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .section-main .block-article--full-width .block-article__inside {
    max-width: none;
    padding-right: 0;
    padding-left: 0;
  }
  .section-main .block-article--bg-left + .block-article > .block-article__inside::before {
    left: -15px;
    width: 650px;
  }
  .section-main .block-article--bg-right + .block-article > .block-article__inside::before {
    right: -15px;
    width: 650px;
  }
}

.section-prefooter__contact {
  padding: 80px 20px 50px;
  text-align: center;
  background: #8dc8d5;
}
.section-prefooter__contact p {
  margin: 0;
  padding-bottom: 35px;
  color: #fff;
  font-size: 36px;
  line-height: 1.2;
}
.section-prefooter__contact a {
  color: #fff;
  font-size: 15px;
}
.section-prefooter__contact a:hover {
  color: #fff;
}
.section-prefooter__contact a::after {
  background: #fff;
}
.section-prefooter .block-article {
  background: #fff;
}
.section-prefooter .block-article__inside {
  padding-top: 45px;
}
@media screen and (min-width: 960px) {
  .section-prefooter .block-article__inside > h2:first-child {
    position: relative;
  }
  .section-prefooter .block-article__inside > h2:first-child::before {
    position: absolute;
    top: -90px;
    right: -30px;
    width: calc(33.3333% + 0.9375rem + 30px);
    height: 110px;
    background: #8dc8d5;
    content: "";
  }
}
@media screen and (min-width: 1200px) {
  .section-prefooter .block-article__inside > h2:first-child::before {
    right: calc(-50vw + 585px);
    width: calc(50vw - 585px + 33.3333% + 0.9375rem);
  }
}

.section-footer {
  color: #fff;
  font-size: 12px;
  background: #082939;
}
.section-footer__inside {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 20px;
}
.section-footer a {
  color: #8dc8d5;
}
.section-footer__navigation a,
.section-footer__navigation strong {
  position: relative;
  display: inline-block;
  color: #e5e7e6;
  font-weight: normal;
  line-height: 25px;
  text-decoration: none;
}
.section-footer__navigation a::after,
.section-footer__navigation strong::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  width: 0;
  height: 1px;
  background: #e5e7e6;
  transition: width 0.5s ease 0s, left 0.5s ease 0s;
  content: "";
}
.section-footer__navigation a:hover::after,
.section-footer__navigation strong:hover::after {
  left: 0;
  width: 100%;
}
.section-footer__navigation > ul > li {
  display: block;
  padding: 9px 0;
  overflow: hidden;
  border-bottom: 1px solid #5d5d5d;
}
.section-footer__navigation > ul > li > a,
.section-footer__navigation > ul > li > strong {
  margin: 0;
  padding: 0;
  color: #e5e7e6;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
}
.section-footer__navigation > ul > li > a::after,
.section-footer__navigation > ul > li > strong::after {
  height: 2px;
}
@media screen and (min-width: 960px) {
  .section-footer__navigation > ul > li > a,
  .section-footer__navigation > ul > li > strong {
    float: left;
    margin-right: 15px;
  }
}
.section-footer__navigation > ul > li ul {
  overflow: hidden;
}
.section-footer__navigation > ul > li li {
  display: inline-block;
  margin: 2px 4px 2px 0;
  padding: 0 10px 0 0;
  border-right: 1px solid #5d5d5d;
}
.section-footer__navigation > ul > li li:last-child {
  margin-right: 0;
  border: none;
}
.section-footer__navigation--aboutus > ul > li {
  border-bottom: none;
}
.section-footer__address {
  width: 50%;
  margin-top: 50px;
}
.section-footer__address p {
  margin: 1.5em 0;
}
.section-footer__social {
  text-align: right;
}
.section-footer__social a {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-left: 25px;
  text-align: left;
  text-indent: -999em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.section-footer__social a[href*="linkedin.com"] {
  background-image: url("../images/linkedin-footer.svg");
}
.section-footer__social a[href*="facebook.com"] {
  background-image: url("../images/facebook-footer.svg");
}
.section-footer__social a[href*="instagram.com"] {
  background-image: url("../images/instagram-footer.svg");
}
.section-footer__scrolltop {
  position: fixed;
  right: 15px;
  bottom: 115px;
  z-index: 100;
  text-align: right;
}
.section-footer__scrolltop a {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: left;
  text-indent: -999em;
  background: #82d8b7 0 0 no-repeat;
  background-size: contain;
  border-radius: 50%;
}
.section-footer__scrolltop a::before {
  position: absolute;
  top: 16px;
  left: 15px;
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotateZ(45deg);
  content: "";
}
@media screen and (min-width: 960px) {
  .section-footer__scrolltop {
    display: none;
  }
}
.section-footer__closure {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50%;
}
.section-footer__copyright {
  margin-top: 70px;
  margin-bottom: 1.5em;
  color: #b7b5b3;
  text-align: right;
}
.section-footer__copyright p {
  margin: 0;
}
@media screen and (min-width: 960px) {
  .section-footer__inside {
    padding-top: 35px;
    padding-bottom: 45px;
  }
  .section-footer__navigations {
    width: 75%;
  }
  .section-footer__address {
    width: 16.6666%;
    margin-top: 0;
    margin-left: 8.3333%;
  }
  .section-footer__closure {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
  }
  .section-footer__social {
    width: 16.6667%;
    text-align: left;
  }
  .section-footer__social a {
    margin-right: 25px;
    margin-left: 0;
  }
  .section-footer__copyright {
    margin: 10px 0 0;
    text-align: left;
  }
  .section-footer__copyright p,
  .section-footer__copyright .mod_navigation,
  .section-footer__copyright ul,
  .section-footer__copyright li {
    display: inline;
  }
  .section-footer__copyright p::after,
  .section-footer__copyright li::after {
    display: inline;
    white-space: pre;
    content: "    |    ";
  }
  .section-footer__copyright li:last-child::after {
    content: none;
  }
}

.block-article h1 {
  margin: 0 0 24px;
  font-size: 40px;
  line-height: 1;
}
.block-article h1 em {
  color: #a82c2c;
  font-style: normal;
}
@media screen and (min-width: 960px) {
  .block-article h1 {
    font-size: 72px;
  }
}
.block-article h2 {
  margin: 0 0 2em;
  color: #082939;
  font-weight: 800;
  font-size: 15px;
}
@media screen and (min-width: 960px) {
  .block-article h2 {
    margin: 3em 0 2em;
  }
}
.block-article h3 {
  margin: 1em 0 1.5em;
  color: #082939;
  font-weight: 700;
  text-transform: uppercase;
}
.block-article *[class^=ce_] + *[class^=ce_] > h3 {
  margin-top: 3.5em;
}
.block-article .ce_text li,
.block-article .ce_intro li {
  margin-bottom: 1em;
}
.block-article .ce_intro {
  margin-bottom: 2em;
  color: #4c4c47;
  font-size: 24px;
}
.block-article .ce_image {
  margin: 3em 0;
  text-align: center;
}
@media screen and (min-width: 600px) {
  .block-article .ce_image {
    padding: 0 30px;
  }
}
.block-article .ce_gallery {
  margin: 3em 0;
}
.block-article .ce_gallery ul {
  margin-right: -10px;
  margin-left: -10px;
}
.block-article .ce_gallery ul::after {
  display: table;
  clear: both;
  content: "";
}
.block-article .ce_gallery li {
  float: left;
  width: 25%;
  padding: 10px;
}
.block-article .googlemap {
  position: relative;
  width: 100%;
  padding-bottom: 400px !important;
}
@media screen and (min-width: 1200px) {
  .block-article .googlemap {
    padding-bottom: 730px !important;
  }
}

.contact-element {
  margin: 3.5em calc(-0.9375rem - 80px) 0 -20px;
  color: #fff;
}
.contact-element p {
  margin: 0;
}
.contact-element a {
  color: inherit;
  text-decoration: none;
}
.contact-element a:hover {
  text-decoration: underline;
}
.contact-element__inside {
  padding: 70px 0 120px 25%;
  background: #8dc8d5;
}
.contact-element h1.contact-element__headline {
  margin: 0 0 2em;
  color: #fff;
  font-size: 36px;
}
.contact-element__name {
  text-transform: uppercase;
}
.contact-element__image {
  margin-top: -80px;
  margin-right: -30px;
  margin-left: 25%;
}
@media screen and (min-width: 480px) {
  .contact-element {
    max-width: 480px;
  }
}
@media screen and (min-width: 960px) {
  .contact-element {
    max-width: none;
    margin: 0 calc(-0.9375rem - 80px) 0 0;
  }
}

.image-link-element {
  margin-bottom: 65px;
}
.image-link-element:last-child {
  margin-bottom: 0;
}
.image-link-element__image {
  margin-right: -30px;
  margin-left: -30px;
}
.image-link-element__image img {
  width: 100%;
}
.image-link-element__text {
  position: relative;
  z-index: 10;
  margin-top: -160px;
  padding: 48px 25px 60px;
  text-align: center;
  background: #fff;
}
.image-link-element__icon {
  width: 55px;
}
.image-link-element__headline {
  margin: 0.75em 0 0.5em;
  font-size: 24px;
  line-height: 1.2;
}
.image-link-element__link {
  margin-top: 12px;
}
@media screen and (min-width: 960px) {
  .image-link-element {
    position: relative;
    display: block;
    margin-bottom: 30px;
  }
  .block-article--bg-left .image-link-element {
    margin-left: -30px;
  }
  .image-link-element__image {
    position: absolute;
    right: 0;
    z-index: 100;
    height: 100%;
    margin: 0;
    overflow: hidden;
  }
  .image-link-element__image img {
    width: auto;
    max-width: none;
    height: 100%;
    max-height: 100%;
  }
  .image-link-element__text {
    width: 100%;
    margin-top: 0;
    padding: 96px 50% 125px 10%;
    text-align: left;
  }
  .image-link-element:nth-child(odd) .image-link-element__image {
    right: auto;
    left: 0;
  }
  .image-link-element:nth-child(odd) .image-link-element__text {
    padding-right: 10%;
    padding-left: 50%;
  }
}

.list-element {
  margin-top: 0;
  margin-bottom: 110px;
}
.col-4:nth-last-child(1) .list-element {
  margin-bottom: 0;
}
.list-element__icon {
  width: 60px;
}
.list-element__headline {
  font-size: 24px;
}
.list-element__link {
  margin-top: 30px;
}
@media screen and (min-width: 960px) {
  .col-4:nth-last-child(2) .list-element, .col-4:nth-last-child(3) .list-element {
    margin-bottom: 0;
  }
}

.partner-element {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.partner-element__item {
  padding: 20px;
}
.partner-element__item img {
  max-height: 100px;
}
.partner-element__item--desktop {
  display: none;
}
.partner-element__item--mobile1, .partner-element__item--mobile2 {
  flex-basis: 50%;
}
.partner-element__item--mobile0, .partner-element__item--mobile3, .partner-element__item--mobile4 {
  flex-basis: 33.3333%;
}
.partner-element__item--mobile3 {
  text-align: left;
}
.partner-element__item--mobile1, .partner-element__item--mobile2, .partner-element__item--mobile4 {
  text-align: center;
}
.partner-element__item--mobile0 {
  text-align: right;
}
@media screen and (min-width: 960px) {
  .partner-element__item {
    padding: 30px;
  }
  .partner-element__item--desktop {
    display: initial;
  }
  .partner-element__item--desktop1, .partner-element__item--desktop2, .partner-element__item--desktop3 {
    flex-basis: 33.3333%;
  }
  .partner-element__item--desktop0, .partner-element__item--desktop4, .partner-element__item--desktop5, .partner-element__item--desktop6 {
    flex-basis: 25%;
  }
  .partner-element__item--desktop4 {
    text-align: left;
  }
  .partner-element__item--desktop1, .partner-element__item--desktop2, .partner-element__item--desktop3, .partner-element__item--desktop5 {
    text-align: center;
  }
  .partner-element__item--desktop6 {
    text-align: right;
  }
}

.process-graph-element {
  margin-top: 60px;
}
.process-graph-element__headline {
  margin: 0 0 10px;
  font-weight: 600;
  font-size: 15px;
}
.process-graph-element__text {
  margin: 0;
  font-size: 13px;
}
.process-graph-element__header {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 50px;
}
.process-graph-element__header::after {
  position: absolute;
  top: 1.2em;
  bottom: 0;
  left: 50%;
  z-index: -1;
  width: 6px;
  margin-left: -3px;
  background: #e4e5ea;
  content: "";
}
.process-graph-element__block--1 {
  width: 50%;
  padding-right: 10px;
  text-align: right;
}
.process-graph-element__block--2 {
  width: 50%;
  padding-left: 10px;
}
.process-graph-element__block--3, .process-graph-element__block--4, .process-graph-element__block--5, .process-graph-element__block--6, .process-graph-element__block--7, .process-graph-element__block--8 {
  position: relative;
  width: 260px;
  min-height: 245px;
  margin: 0 auto;
  padding: 75px 0;
  text-align: center;
}
.process-graph-element__block--3::before, .process-graph-element__block--4::before, .process-graph-element__block--5::before, .process-graph-element__block--6::before, .process-graph-element__block--7::before, .process-graph-element__block--8::before {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  display: block;
  width: 170px;
  height: 170px;
  margin-left: -85px;
  background: #fff 65px 20px no-repeat;
  background-size: 40px 40px;
  border-radius: 50%;
  content: "";
}
.process-graph-element__block--3::after, .process-graph-element__block--4::after, .process-graph-element__block--5::after, .process-graph-element__block--6::after, .process-graph-element__block--7::after, .process-graph-element__block--8::after {
  position: absolute;
  top: 170px;
  bottom: 0;
  left: 50%;
  z-index: -1;
  display: block;
  width: 6px;
  margin-left: -3px;
  background: #e4e5ea;
  content: "";
}
.process-graph-element__block--3::before {
  background-image: url("../../images/process-graph--mobile3.svg");
}
.process-graph-element__block--4::before {
  background-image: url("../../images/process-graph--mobile4.svg");
}
.process-graph-element__block--5::before {
  background-image: url("../../images/process-graph--mobile5.svg");
}
.process-graph-element__block--6::before {
  background-image: url("../../images/process-graph--mobile6.svg");
}
.process-graph-element__block--7::before {
  background-image: url("../../images/process-graph--mobile7.svg");
}
.process-graph-element__block--8 {
  min-height: 0;
  padding-bottom: 0;
}
.process-graph-element__block--8::before {
  background-image: url("../../images/process-graph--mobile8.svg");
}
.process-graph-element__block--8::after {
  content: none;
}
@media screen and (min-width: 960px) {
  .process-graph-element {
    width: 900px;
  }
  .process-graph-element__header {
    padding-bottom: 20px;
  }
  .process-graph-element__header::after {
    content: none;
  }
  .process-graph-element__body {
    position: relative;
    height: 600px;
    background: url("../../images/process-graph--desktop.svg") 50% 0 no-repeat;
    background-size: contain;
  }
  .process-graph-element__block::before, .process-graph-element__block::after {
    content: none;
  }
  .process-graph-element__block--1 {
    width: 300px;
    text-align: left;
  }
  .process-graph-element__block--2 {
    width: 300px;
    text-align: right;
  }
  .process-graph-element__block--3, .process-graph-element__block--4, .process-graph-element__block--5, .process-graph-element__block--6, .process-graph-element__block--7, .process-graph-element__block--8 {
    position: absolute;
    min-height: 0;
    margin: 0;
    padding: 0;
  }
  .process-graph-element__block--3, .process-graph-element__block--5, .process-graph-element__block--7 {
    left: 40px;
    text-align: right;
  }
  .process-graph-element__block--4, .process-graph-element__block--6, .process-graph-element__block--8 {
    left: 620px;
    text-align: left;
  }
  .process-graph-element__block--3 {
    top: 60px;
  }
  .process-graph-element__block--4 {
    top: 160px;
  }
  .process-graph-element__block--5 {
    top: 260px;
  }
  .process-graph-element__block--6 {
    top: 360px;
  }
  .process-graph-element__block--7 {
    top: 470px;
  }
  .process-graph-element__block--8 {
    top: 530px;
  }
}

.team-element__title {
  margin: 0 0 0.5em !important;
  font-size: 22px !important;
  cursor: pointer;
}
.team-element__title:hover, .team-element__item--active .team-element__title {
  color: #a82c2c;
}
.team-element__details {
  display: none;
  margin: 40px -30px 50px 22%;
}
.team-element__item--active .team-element__details {
  display: block;
}
.team-element__image {
  margin: 0 0 30px;
}
.team-element__name {
  font-weight: 600;
  text-transform: uppercase;
}
@media screen and (min-width: 960px) {
  .team-element {
    position: relative;
    min-height: 660px;
  }
  .team-element__image img {
    width: 860px;
    max-width: none;
  }
  .team-element__details {
    position: absolute;
    top: 0;
    right: 0;
    width: 66%;
    margin: 0;
  }
}

.job-list__item {
  position: relative;
  margin-right: -30px;
  margin-bottom: 30px;
  padding: 90px 60px 90px 30px;
  background: #fff;
}
.job-list__item:last-child {
  margin-bottom: 0;
}
.job-list__headline {
  margin: 0 0 15px;
  font-size: 24px;
  line-height: 1.2;
}
.job-list__date {
  color: #646464;
  font-weight: 300;
}
.job-list__text {
  margin: 0 0 25px;
}
@media screen and (min-width: 960px) {
  .job-list {
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 1200px) {
  .job-list__item {
    margin-right: 0;
    padding: 90px 30px;
  }
  .job-list__item::before {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 0;
    background: #fff;
    content: "";
  }
  .block-article--bg-left .job-list__item::before {
    right: calc(-50vw + 615px);
    width: 50vw;
  }
  .block-article--bg-right .job-list__item::before {
    left: calc(-50vw + 615px);
    width: 50vw;
  }
}

.job-details__intro {
  font-size: 18px;
  line-height: 1.5;
}
.job-details__intro strong {
  display: block;
  color: #a82c2c;
  font-size: 24px;
  text-transform: uppercase;
}
.job-details__share {
  display: flex;
  gap: 20px;
  margin-top: 25px;
}
.job-details__share a {
  display: block;
  width: 30px;
  height: 30px;
  color: #83949c;
}
.job-details__share a:hover {
  color: #a82c2c;
}
.job-details h2 {
  margin: 80px 0 50px;
}
.job-details__notes {
  margin: 80px 0 50px;
}
.job-details__contact {
  margin-top: 100px;
}
.job-details__contact--desktop {
  display: none;
}
.job-details__outro {
  margin: 50px 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
}
@media screen and (min-width: 960px) {
  .job-details__intro {
    font-size: 24px;
  }
  .job-details__intro p {
    margin-bottom: 0;
  }
  .job-details__intro strong {
    font-size: 36px;
  }
  .job-details__textblock {
    margin-top: 100px;
  }
  .job-details__contact {
    margin-top: 0;
  }
  .job-details__contact--desktop {
    display: block;
  }
  .job-details__contact--mobile {
    display: none;
  }
}

@media screen and (min-width: 960px) {
  .mod_newslist {
    display: flex;
  }
}

.news-list {
  margin-top: 45px;
  margin-bottom: 60px;
}
.news-list__item img {
  width: 100%;
  height: auto;
}
.news-list__date {
  display: block;
  font-weight: 300;
}
.news-list__headline {
  margin: 1.5em 0 !important;
  color: #646464 !important;
  font-weight: 400 !important;
  font-size: inherit !important;
}
.news-list .tns-liveregion {
  display: none;
}
.news-list .tns-nav {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
.news-list .tns-nav button {
  display: block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  font-size: 8px;
  line-height: 8px;
  text-indent: -999em;
  background: #eeeff4;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}
.news-list .tns-nav button.tns-nav-active {
  background: #a82c2c;
}
@media screen and (min-width: 960px) {
  .news-list .tns-nav button {
    width: 15px;
    height: 15px;
    margin-right: 15px;
    font-size: 15px;
    line-height: 15px;
  }
}

.training-list__item {
  position: relative;
  margin-right: -30px;
  margin-bottom: 30px;
  padding: 90px 60px 90px 30px;
  background: #fff;
}
.training-list__item:last-child {
  margin-bottom: 0;
}
.training-list__headline {
  margin: 0 0 15px;
  font-size: 24px;
  line-height: 1.2;
}
.training-list__text {
  margin: 0 0 25px;
}
.training-list__date {
  display: block;
  margin: 0 0 25px;
  color: #646464;
  font-weight: 300;
}
@media screen and (min-width: 960px) {
  .training-list {
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 1200px) {
  .training-list__item {
    margin-right: 0;
    padding: 90px 30px;
  }
  .training-list__item::before {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 0;
    background: #fff;
    content: "";
  }
  .block-article--bg-left .training-list__item::before {
    right: calc(-50vw + 615px);
    width: 50vw;
  }
  .block-article--bg-right .training-list__item::before {
    left: calc(-50vw + 615px);
    width: 50vw;
  }
}

.training-detail__intro {
  font-size: 18px;
  line-height: 1.5;
}
.training-detail__intro strong {
  display: block;
  color: #a82c2c;
  font-size: 24px;
  text-transform: uppercase;
}
.training-detail__summary, .training-detail__textblock {
  margin-top: 50px;
}
.training-detail__summary p:first-child, .training-detail__textblock p:first-child {
  margin-top: 0;
}
.training-detail h2 {
  margin: 80px 0 50px;
}
.training-detail__notes {
  margin: 80px 0 50px;
}
.training-detail__contact {
  margin-top: 100px;
}
.training-detail__contact--desktop {
  display: none;
}
@media screen and (min-width: 960px) {
  .training-detail__intro {
    font-size: 24px;
  }
  .training-detail__intro p {
    margin-bottom: 0;
  }
  .training-detail__intro strong {
    font-size: 36px;
  }
  .training-detail__textblock {
    margin-top: 100px;
  }
  .training-detail__contact {
    margin-top: 0;
  }
  .training-detail__contact--desktop {
    display: block;
  }
  .training-detail__contact--mobile {
    display: none;
  }
}