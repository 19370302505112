.widget {
    position: relative;
    display: block;
    margin: 0 0 18px;
    color: #4c4c47;
    font-size: inherit;

    > label,
    .radio_container > legend,
    .checkbox_container > legend {
        position: absolute;
        top: -8px;
        left: 0;
        margin: 0 1px 0 0;
        padding: 0;
        font-size: 12px;
        background: #fff;
        cursor: text;
        transition: all 0.2s;

        span.mandatory {
            margin-left: 3px;
        }
    }

    select {
        appearance: none;
    }

    textarea {
        width: 100%;
    }

    input.text,
    select,
    textarea,
    .radio_container,
    .checkbox_container {
        width: 100%;
        padding: 16px 0 8px;
        font-size: inherit;
        border: 0;
        border-bottom: 1px solid #646464;
        border-radius: 0;

        &.no-label {
            padding-top: 15px;
            padding-bottom: 13px;
        }

        &::placeholder {
            color: #b5b5b5;
            opacity: 1;
            transition: all 0.2s;
        }

        &:placeholder-shown:not(:focus, .no-label)::placeholder {
            opacity: 0;
        }

        &:focus {
            border-color: #a82c2c;
            outline: none;
        }

        &:focus + label {
            color: #a82c2c;
        }

        &:placeholder-shown:not(:focus) + label,
        &.no-placeholder:not(:focus) + label {
            top: 13px;
            color: #b5b5b5;
            font-weight: 300;
            font-size: inherit;
        }
    }

    .radio_container,
    .checkbox_container {
        border-bottom: 0;

        & > span {
            display: inline-block;
            margin-right: 20px;

            > label {
                margin-left: 5px;
            }
        }
    }

    &-submit {
        margin-top: 80px;
        border: none;

        button {
            @extend .block-link;
        }
    }

    p.error {
        position: relative;
        margin: 10px 0 0;
        padding: 8px 23px 7px;
        color: #fff;
        font-size: 0.8em;
        background: #a82c2c;

        &::before {
            position: absolute;
            top: -5px;
            left: 28px;
            width: 10px;
            height: 10px;
            background: #a82c2c;
            transform: rotateZ(45deg);
            content: '';
        }
    }

    &.explanation--small {
        font-size: 10px;
        line-height: 1;

        @include screen(medium) {
            margin-top: -2em;
        }
    }

    &-fineuploader {
        &::after {
            display: table;
            clear: both;
            content: '';
        }

        label {
            position: initial;
            margin: 0;
            font-size: inherit;
        }

        .qq-upload-button,
        .qq-upload-button-hover {
            display: inline-block;
            width: auto;
            padding: 5px 10px;
            color: #fff;
            font-size: 12px;
            line-height: 19px;
            background: $link-color;
            border: none;
            border-radius: 5px;
        }

        .qq-upload-list li {
            color: inherit !important;
            background: transparent !important;
        }

        @include screen(medium) {
            .qq-upload-button {
                float: left;
                width: 130px;
            }

            .qq-drop-processing,
            .qq-upload-list {
                margin-left: 150px;
            }
        }
    }
}

fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

@include screen(medium) {
    .widget {
        margin-bottom: 23px;
    }
}
