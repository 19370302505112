.contact-element {
    margin: 3.5em calc(-0.9375rem + -80px) 0 -20px;
    color: #fff;

    p {
        margin: 0;
    }

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__inside {
        padding: 70px 0 120px 25%;
        background: #8dc8d5;
    }

    h1.contact-element__headline {
        @extend .headline;
        margin: 0 0 2em;
        color: #fff;
        font-size: 36px;
    }

    &__name {
        text-transform: uppercase;
    }

    &__image {
        margin-top: -80px;
        margin-right: -30px;
        margin-left: 25%;
    }

    @include screen(480px) {
        max-width: 480px;
    }

    @include screen(medium) {
        max-width: none;
        margin: 0 calc(-0.9375rem + -80px) 0 0;
    }
}
