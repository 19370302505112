.image-link-element {
    margin-bottom: 65px;

    &:last-child {
        margin-bottom: 0;
    }

    &__image {
        margin-right: -30px;
        margin-left: -30px;

        img {
            width: 100%;
        }
    }

    &__text {
        position: relative;
        z-index: 10;
        margin-top: -160px;
        padding: 48px 25px 60px;
        text-align: center;
        background: #fff;
    }

    &__icon {
        width: 55px;
    }

    &__headline {
        @extend .headline;
        margin: 0.75em 0 0.5em;
        font-size: 24px;
        line-height: 1.2;
    }

    &__link {
        @extend .block-link;
        margin-top: 12px;
    }

    @include screen(medium) {
        position: relative;
        display: block;
        margin-bottom: 30px;

        .block-article--bg-left & {
            margin-left: -30px;
        }

        &__image {
            position: absolute;
            right: 0;
            z-index: 100;
            height: 100%;
            margin: 0;
            overflow: hidden;

            img {
                width: auto;
                max-width: none;
                height: 100%;
                max-height: 100%;
            }
        }

        &__text {
            width: 100%;
            margin-top: 0;
            padding: 96px 50% 125px 10%;
            text-align: left;
        }

        &:nth-child(odd) {
            .image-link-element {
                &__image {
                    right: auto;
                    left: 0;
                }

                &__text {
                    padding-right: 10%;
                    padding-left: 50%;
                }
            }
        }
    }
}
