.section-header {
    .layout--image & {
        min-height: 75vw;
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: contain;

        @include screen(medium) {
            min-height: auto;
            background-size: cover;
        }
    }

    .layout--home.layout--image & {
        min-height: 150vw;

        @include screen(medium) {
            min-height: auto;
        }
    }

    &__inside {
        @extend .section-center;
        position: relative;
        padding-top: 40px;
        padding-bottom: 0;

        @include screen(medium) {
            padding-top: 60px;
        }
    }

    .layout--image & {
        &.layout--home .section-header__inside {
            height: 100vh;
        }

        @include screen(medium) {
            &__inside {
                height: 750px;
            }
        }
    }

    &__logo {
        display: block;
        width: 130px;
        height: 40px;
        margin-top: 8px;
        text-indent: -999em;
        background: url('../images/logo.svg') 0 0 / contain no-repeat;
        background-size: contain;

        @include screen(medium) {
            float: left;
            width: 175px;
            height: 50px;
        }
    }

    &__navigation {
        &-toggle {
            position: absolute;
            top: 32px;
            right: 30px;
            z-index: 101;
            display: block;
            width: 50px;
            height: 50px;
            text-indent: -999em;
            background: #fff url('../images/hamburger.svg') 52% 50% no-repeat;
            background-size: 25px 18px;
            border-radius: 100%;
            cursor: pointer;
        }

        &-overlay {
            position: absolute;
            inset: 0;
            z-index: 100;
            display: none;
            height: 100vh;
            background: #fff;
        }

        &--active {
            .section-header__navigation-toggle {
                background-image: url('../images/cross.svg');
            }

            .section-header__navigation-overlay {
                display: block;
            }
        }

        .mod_navigation {
            height: 50vh;
            margin: 20vh 0 0;
            padding: 0 40px;

            ul {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }

            li {
                padding: 5px 0;
            }

            a,
            strong {
                position: relative;
                display: inline-block;
                color: #082939;
                font-weight: 400;
                font-size: 25px;
                text-decoration: none;

                &::after {
                    position: absolute;
                    bottom: -4px;
                    left: 50%;
                    display: block;
                    width: 0;
                    height: 6px;
                    background: #082939;
                    transition:
                        width 0.5s ease 0s,
                        left 0.5s ease 0s;
                    content: '';
                }

                &:hover,
                &.active,
                &.trail {
                    &::after {
                        left: 0;
                        width: 100%;
                    }
                }

                &.login {
                    color: $link-color;

                    &::after {
                        background: $link-color;
                    }
                }
            }
        }

        &-social {
            position: absolute;
            bottom: 40px;
            left: 40px;

            a {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 15px;
                text-indent: -999em;
                background-size: contain;

                &[href*='linkedin.com'] {
                    background: url('../images/linkedin-header.svg') 0 0 no-repeat;
                }

                &[href*='facebook.com'] {
                    background: url('../images/facebook-header.svg') 0 0 no-repeat;
                }

                &[href*='instagram.com'] {
                    background: url('../images/instagram-header.svg') 0 0 no-repeat;
                }
            }
        }

        @include screen(medium) {
            &-toggle {
                display: none;
            }

            &-overlay {
                position: static;
                display: block;
                height: auto;
                background: none;
            }

            &-social {
                display: none;
            }

            .mod_navigation {
                height: auto;
                margin: 18px 0 0;
                padding: 0;

                ul {
                    flex-direction: row;
                    justify-content: flex-end;
                }

                li {
                    margin-left: 50px;
                    padding: 0;
                }

                a,
                strong {
                    padding-bottom: 9px;
                    font-size: 18px;
                    line-height: 1;

                    .layout--white-nav & {
                        color: #fff;

                        &::after {
                            background: #fff;
                        }
                    }
                }
            }
        }
    }
}
