.section-teaser {
    position: relative;
    margin-top: -90px;

    &__inside {
        display: flex;

        @include screen(1260px) {
            max-width: 1260px;
            margin-right: auto;
            margin-bottom: 70px;
            margin-left: auto;
            padding-right: 15px;
            padding-left: 15px;

            &::before {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 33%;
                background: #fff;
                content: '';

                .layout--home & {
                    right: auto;
                    left: 0;
                }
            }
        }
    }

    .block-article {
        position: relative;
        width: 100%;

        &__inside {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            padding: 0;

            .layout--home & {
                justify-content: flex-start;
            }
        }
    }
}

.teaser-element {
    position: relative;
    flex-basis: 80%;
    padding: 30px 30px 0;
    background: #fff;

    &__headline {
        @extend .headline;
        margin: 0;
        font-size: 18px;
        line-height: 1;
    }

    &__text,
    &__link {
        margin: 1em 0;
        font-size: 12px;
    }

    &__link {
        @extend .block-link;
        margin-top: 2em;
    }

    .layout--home &,
    &--with-text {
        .teaser-element__headline {
            padding-right: 30px;

            > span {
                position: absolute;
                display: inline-block;
                width: 15px;
                height: 10px;
                margin-top: 4px;
                margin-left: 0.5em;
                background: url('../images/teaser-link.svg') 0 0 no-repeat;
            }
        }

        &:hover {
            .teaser-element__headline > span {
                animation: teaser-link 1s ease infinite;
            }

            @keyframes teaser-link {
                0% {
                    margin-left: 0.5em;
                }

                50% {
                    margin-left: 1em;
                }

                100% {
                    margin-left: 1em;
                }
            }
        }
    }

    @include screen(medium, max-width) {
        &--with-text &__link,
        .layout--home &__link {
            position: absolute;
            inset: 0;
            text-indent: -999em;

            &::after {
                content: none;
            }
        }
    }

    .layout--home & {
        &__headline {
            font-size: 24px;

            > span {
                margin-top: 7px !important;
            }
        }

        &__text {
            display: none;
        }
    }

    @include screen(560px) {
        flex-basis: 50%;
    }

    @include screen(medium) {
        flex-basis: 33%;

        &__headline {
            font-size: 36px !important;
        }

        &__text,
        &__link {
            font-size: 15px;
        }

        .layout--home &__headline,
        &--with-text &__headline {
            padding-right: 0;

            > span {
                display: none !important;
            }
        }

        &--with-text &__link {
            position: relative;
            margin-top: 1em;
            text-indent: 0;

            &::before {
                content: none;
            }
        }
    }
}
