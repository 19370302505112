.block-article {
    h1 {
        @extend .headline;
        margin: 0 0 24px;
        font-size: 40px;
        line-height: 1;

        em {
            color: $link-color;
            font-style: normal;
        }

        @include screen(medium) {
            font-size: 72px;
        }
    }

    h2 {
        margin: 0 0 2em;
        color: #082939;
        font-weight: 800;
        font-size: 15px;

        @include screen(medium) {
            margin: 3em 0 2em;
        }
    }

    h3 {
        margin: 1em 0 1.5em;
        color: #082939;
        font-weight: 700;
        text-transform: uppercase;
    }

    *[class^='ce_'] + *[class^='ce_'] > h3 {
        margin-top: 3.5em;
    }

    .ce_text,
    .ce_intro {
        @extend .list;

        li {
            margin-bottom: 1em;
        }
    }

    .ce_intro {
        margin-bottom: 2em;
        color: #4c4c47;
        font-size: 24px;
    }

    .ce_list {
        @extend .list;
    }

    .ce_image {
        margin: 3em 0;
        text-align: center;

        @include screen(600px) {
            padding: 0 30px;
        }
    }

    .ce_gallery {
        margin: 3em 0;

        ul {
            margin-right: -10px;
            margin-left: -10px;

            &::after {
                display: table;
                clear: both;
                content: '';
            }
        }

        li {
            float: left;
            width: 25%;
            padding: 10px;
        }
    }

    .googlemap {
        position: relative;
        width: 100%;
        padding-bottom: 400px !important;

        @include screen(large) {
            padding-bottom: 730px !important;
        }
    }
}

@import 'elements/contact';
@import 'elements/image-link';
@import 'elements/list';
@import 'elements/partner';
@import 'elements/process-graph';
@import 'elements/team';
@import 'modules/jobs';
@import 'modules/news';
@import 'modules/training';
