$link-color: #a82c2c;

@mixin screen($size, $prop: 'min-width') {
    $width: $size;
    $sizes: (
        medium: 960px,
        large: 1200px,
    );

    @if map_has_key($sizes, $size) {
        $width: map_get($sizes, $size);
    }

    @media screen and (#{$prop}: $width) {
        @content;
    }
}

@import 'layout';
@import 'forms';
@import 'grid';
@import 'header';
@import 'teaser';
@import 'title';
@import 'main';
@import 'prefooter';
@import 'footer';
@import 'elements';
