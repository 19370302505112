.section-main {
    clear: both;
    overflow-x: hidden;

    .block-article {
        &__inside {
            @extend .section-center;
            margin-top: 100px;
            margin-bottom: 100px;
        }

        &--full-width {
            .block-article__inside {
                margin-bottom: 0;
                padding-right: 0;
                padding-left: 0;
            }
        }

        &--bg-left,
        &--bg-right {
            position: relative;

            &::before {
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: -1;
                background: #eeeff4;
                content: '';
            }

            .block-article__inside {
                padding-top: 95px;
                padding-bottom: 100px;
            }

            &:last-child {
                .block-article__inside {
                    margin-bottom: 0 !important;
                }
            }
        }

        &--bg-left::before {
            right: 0;
            left: 10px;

            @include screen(large) {
                left: calc(50% - 615px);
            }
        }

        &--bg-right::before {
            right: 10px;
            left: 0;

            @include screen(large) {
                right: calc(50% - 615px);
            }
        }

        &:first-child {
            .row > .col-4:first-child {
                :not(.formbody) h2 {
                    display: none;

                    @include screen(medium) {
                        display: block;
                    }
                }
            }
        }

        @include screen(medium) {
            &__inside {
                margin-top: 120px;
                margin-bottom: 130px;
            }

            .row > .col-4:first-child h2 {
                margin-top: 10px !important;
            }

            .row > .col-6:first-child h2 {
                margin-top: 0 !important;
            }

            .ce_text:first-child,
            .ce_intro:first-child {
                p:first-child {
                    margin-top: 0;
                }
            }

            &--bg-left,
            &--bg-right {
                h2 {
                    margin-top: 0;
                }
            }

            &--bg-right {
                + .block-article {
                    margin-top: -30px;

                    > .block-article__inside > h2:first-child {
                        position: relative;

                        &::before {
                            position: absolute;
                            top: -100px;
                            right: -30px;
                            width: 500px;
                            height: 110px;
                            background: #eeeff4;
                            content: '';
                        }
                    }
                }
            }
        }

        @include screen(large) {
            &__inside {
                max-width: 1200px;
                margin-right: auto;
                margin-left: auto;
                padding-right: 15px;
                padding-left: 15px;
            }

            &--full-width {
                .block-article__inside {
                    max-width: none;
                    padding-right: 0;
                    padding-left: 0;
                }
            }

            &--bg-left + .block-article > .block-article__inside {
                &::before {
                    left: -15px;
                    width: 650px;
                }
            }

            &--bg-right + .block-article > .block-article__inside {
                &::before {
                    right: -15px;
                    width: 650px;
                }
            }
        }
    }
}
