.section-title {
    .block-article {
        &__inside {
            margin-bottom: -50px;
            padding: 100px 30px 0;
        }

        p {
            display: none;
        }

        @include screen(medium) {
            padding-top: 50px;
            line-height: 1.25;

            p {
                display: block;
                color: #646464;
                font-weight: 300;
                font-size: 24px;
            }
        }

        @include screen(medium) {
            .layout--image & {
                position: absolute;
                top: 300px;
                left: 0;
                width: 100%;

                &,
                .block-article__inside {
                    padding-top: 0;
                    padding-bottom: 0;
                }

                h1,
                p {
                    width: 75%;
                }
            }

            .layout--white-title & {
                h1,
                p {
                    color: #fff;
                }
            }
        }

        @include screen(large) {
            &__inside {
                max-width: 1200px;
                margin-right: auto;
                margin-left: auto;
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }
}
