.partner-element {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__item {
        padding: 20px;

        img {
            max-height: 100px;
        }

        &--desktop {
            display: none;
        }

        &--mobile1,
        &--mobile2 {
            flex-basis: 50%;
        }

        &--mobile0,
        &--mobile3,
        &--mobile4 {
            flex-basis: 33.3333%;
        }

        &--mobile3 {
            text-align: left;
        }

        &--mobile1,
        &--mobile2,
        &--mobile4 {
            text-align: center;
        }

        &--mobile0 {
            text-align: right;
        }
    }

    @include screen(medium) {
        &__item {
            padding: 30px;

            &--desktop {
                display: initial;
            }

            &--desktop1,
            &--desktop2,
            &--desktop3 {
                flex-basis: 33.3333%;
            }

            &--desktop0,
            &--desktop4,
            &--desktop5,
            &--desktop6 {
                flex-basis: 25%;
            }

            &--desktop4 {
                text-align: left;
            }

            &--desktop1,
            &--desktop2,
            &--desktop3,
            &--desktop5 {
                text-align: center;
            }

            &--desktop6 {
                text-align: right;
            }
        }
    }
}
