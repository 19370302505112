.section-prefooter {
    &__contact {
        padding: 80px 20px 50px;
        text-align: center;
        background: #8dc8d5;

        p {
            @extend .headline;
            margin: 0;
            padding-bottom: 35px;
            color: #fff;
            font-size: 36px;
            line-height: 1.2;
        }

        a {
            @extend .block-link;
            color: #fff;
            font-size: 15px;

            &:hover {
                color: #fff;
            }

            &::after {
                background: #fff;
            }
        }
    }

    .block-article {
        background: #fff;

        &__inside {
            padding-top: 45px;
            @extend .section-center;

            @include screen(medium) {
                > h2:first-child {
                    position: relative;

                    &::before {
                        position: absolute;
                        top: -90px;
                        right: -30px;
                        width: calc(33.3333% + 0.9375rem + 30px);
                        height: 110px;
                        background: #8dc8d5;
                        content: '';
                    }
                }
            }

            @include screen(large) {
                > h2:first-child::before {
                    right: calc(-50vw + 585px);
                    width: calc(50vw - 585px + 33.3333% + 0.9375rem);
                }
            }
        }
    }
}
